import PropTypes from 'prop-types'
import classNames from 'classnames'

import Typography from '../typography'
import Image from '../image'

import { useBreakpoint } from '../../../hooks/breakpoint'

import LinkedinIcon from '../icon/linkedin.svg'
import GuateAprendeIcon from '../../../public/img/bantrab/logo-bannu-03.svg'

import styles from '../../../styles/bantrab/components/home.module.scss'

const Teachers = ({ title, highlightedTitleText1, highlightedTitleText2, teachers, ...attrs }) => {
  const breakpoint = useBreakpoint()

  title = title.replace(new RegExp(`\\b${highlightedTitleText1.trim()}\\b`, 'g'), '')
  title = title.replace(new RegExp(`\\b${highlightedTitleText2.trim()}\\b`, 'g'), '')

  attrs.className = classNames(
    attrs.className,
    [
      'relative',
      'py-20',
      'md:py-24',
      'md:bg-white'
    ],
    {
      [styles.teachers]: true
    }
  )

  return <section {...attrs}>
    <div
      className={
        classNames([
          'relative',
          'container',
          'px-4'
        ])
      }>
      <div
        className="grid grid-cols-5 gap-16 mb-12">
        <div
          className="relative z-0 text-center md:text-right col-span-5 md:col-span-3 lg:col-span-3">

          <Typography
            weight={'black'}
            size={breakpoint.mdAndUp ? 'six' : 'five'}
            className={'text-turquoise-600 mb-4'}>
            {title}<br/>
            <Typography
              tag={'span'}
              weight={'black'}
              size={breakpoint.mdAndUp ? 'six' : 'five'}
              className={'text-gray-400'}>
              {highlightedTitleText1}
            </Typography>{' '}
            <Typography
              tag={'span'}
              weight={'black'}
              size={breakpoint.mdAndUp ? 'eight' : 'six'}
              className={'text-green-500'}>
              {highlightedTitleText2}
            </Typography>
          </Typography>

          <GuateAprendeIcon
            className="absolute z-0 -top-16 -right-16 transform translate-x-full"
            width={256}/>
        </div>
      </div>

      <div
        className={
          classNames([
            'flex',
            'flex-row',
            'flex-wrap',
            'justify-center'
          ])
        }>
        {
          teachers.map((teacher, index) => {
            return <div
              key={index}
              className={
                classNames([
                  'relative',
                  'flex',
                  'flex-column',
                  'items-end',
                  'bg-no-repeat',
                  'bg-center',
                  'bg-cover',
                  'p-6',
                  'm-4',
                  'overflow-hidden',
                  index % 2 ? 'bg-turquoise-600' : 'bg-green-600'
                ])
              }
              style={{
                borderRadius: '0 1.5rem 1.5rem 1.5rem',
                flexBasis: breakpoint.lgAndUp ? 'calc(25% - 2rem)' : (breakpoint.mdAndUp ? 'calc(50% - 2rem)' : '100%'),
                height: breakpoint.mdAndUp ? '400px' : '360px'
              }}>
              <div className={classNames([
                'absolute',
                'w-full',
                'h-full',
                'top-0',
                'left-0'
              ])}>
                <Image
                  src={teacher.picture.meta.downloadUrl}
                  className={classNames([
                    'w-full',
                    'h-full',
                    'object-cover'
                  ])}
                />
              </div>
              <div
                className={'relative'}>
                <Typography
                  weight={'black'}
                  size={'five'}
                  className={'text-white mb-1'}>
                  {teacher.name}
                </Typography>
                <Typography
                  size={'two'}
                  className={'text-white mb-4'}>
                  {teacher.ocupation}
                </Typography>
                <Typography
                  tag={'a'}
                  href={teacher.linkedin}
                  target={'_blank'}
                  rel={'noreferrer'}
                  className={'flex flex-row items-center'}>
                  <LinkedinIcon
                    className={'mr-3 text-white'}
                    width={24}
                    height={24} />
                  <Typography
                    size={'two'}
                    className={'text-white'}>
                    Ver perfil
                  </Typography>
                </Typography>
              </div>
            </div>
          })
        }
      </div>
    </div>
  </section>
}

Teachers.propTypes = {
  title: PropTypes.string,
  highlightedTitleText1: PropTypes.string,
  highlightedTitleText2: PropTypes.string,
  teachers: PropTypes.array
}
export default Teachers
