import PropTypes from 'prop-types'
import classNames from 'classnames'
import Image from 'next/image'

import Typography from '../typography'
import SignupCall from '../auth/signup-call'
import CourseCard from '../courses/card'
import { getShortName } from '../user/functions'
import { useAuth } from '../../../hooks/auth'
import { useBreakpoint } from '../../../hooks/breakpoint'

const Header = ({ title, highlightedTitleText, text, image, bannuBannerText, benefits, integrationSettings, ...attrs }) => {
  const breakpoint = useBreakpoint()
  const auth = useAuth()

  title = title.replace(highlightedTitleText, '')

  attrs.className = classNames(
    attrs.className,
    [
      'relative',
      'py-9',
      'md:py-20',
      'lg:bg-white'
    ]
  )

  return <section {...attrs}>
    <div
      className={
        classNames([
          'relative',
          'container',
          'pt-8',
          'md:pt-0',
          'px-4',
          'mb-10',
          'md:mb-20'
        ])
      }>

      {
        (auth && auth.isAuthenticated && auth.user)
          ? <div>
            <Typography
              weight={'black'}
              size={breakpoint.mdAndUp ? 'eight' : 'six'}
              className={'text-gray-500 mb-4'}>
              Hola,{' '}
              <Typography
                tag={'span'}
                weight={'black'}
                size={breakpoint.mdAndUp ? 'eight' : 'six'}
                className={'text-green-600'}>
                {getShortName(auth.user)}
              </Typography>
              . ¡Que bueno verte!
            </Typography>
            <div
              className={'mb-4 -mx-4 grid grid-cols-3'}>
              {
                auth.enrolledCourses.slice(0, 3).map(
                  item => {
                    return <CourseCard
                      key={item.id}
                      course={item}
                      integrationSettings={integrationSettings} />
                  }
                )
              }
            </div>
          </div>
          : <div
            className={
              classNames([
                'flex',
                'flex-row',
                'flex-wrap',
                'justify-center',
                '-mx-4'
              ])
            }>
            <div
              className={
                classNames([
                  'relative',
                  'flex',
                  'flex-col',
                  'justify-center',
                  'px-4'
                ])
              }
              style={{
                flexBasis: breakpoint.mdAndUp ? '50%' : '100%',
                width: breakpoint.mdAndUp ? '50%' : '100%'
              }}>
              <Typography
                weight={'black'}
                size={breakpoint.mdAndUp ? 'eight' : 'six'}
                className={'text-green-600 mb-4'}>
                {title}<br/>
                <Typography
                  tag={'span'}
                  weight={'black'}
                  size={breakpoint.mdAndUp ? 'eight' : 'six'}
                  className={'text-turquoise-600'}>
                  {highlightedTitleText}
                </Typography>
              </Typography>

              <Typography
                size={breakpoint.mdAndUp ? 'three' : 'two'}
                className={'text-green-600 mb-4'}>
                {text}
              </Typography>

              <SignupCall
                className={'w-full sm:w-4/5 md:w-full lg:w-4/5'} />

            </div>
            <div
              className={
                classNames([
                  'relative',
                  'flex',
                  'flex-col',
                  'justify-center',
                  'items-center',
                  'px-4',
                  'mt-8',
                  'md:mt-0'
                ])
              }
              style={{
                flexBasis: breakpoint.mdAndUp ? '50%' : '100%',
                width: breakpoint.mdAndUp ? '50%' : '100%'
              }}>
              <Image
                src={image.meta.downloadUrl}
                alt={image.title}
                width={image.width}
                height={image.height}
                className={'block z-0'}
              />
            </div>
          </div>
      }
    </div>

    <div
      className={
        classNames([
          'relative',
          'md:container',
          'px-0',
          'md:px-4',
          'mb-10'
        ])
      }>
      <div
        className={
          classNames([
            'relative',
            'md:container',
            'bg-red-500',
            'px-5',
            'py-5',
            'md:px-20',
            'md:py-10',
            'flex',
            'flex-col',
            'md:flex-row'
          ])
        }
        style={{
          borderRadius: breakpoint.mdAndUp ? '0 2rem 2rem 2rem' : null
        }}>
        <div
          className={'flex-grow'}>
          <Typography
            size={breakpoint.mdAndUp ? 'three' : 'two'}
            className={'text-white text-center'}>
            {bannuBannerText}
          </Typography>
        </div>
      </div>
    </div>

    <div
      className={
        classNames([
          'relative',
          'container',
          'p-4'
        ])
      }>
      <div
        className={
          classNames([
            'flex',
            'flex-row',
            'flex-wrap',
            'justify-center',
            '-m-4'
          ])
        }>
        {
          benefits.map((benefit, index) => {
            return <div
              key={index}
              className={
                classNames([
                  'relative',
                  'p-6',
                  'm-4',
                  'overflow-hidden',
                  index % 2 ? 'bg-turquoise-600' : 'bg-green-600'
                ])
              }
              style={{
                borderRadius: '0 1.5rem 1.5rem 1.5rem',
                flexBasis: breakpoint.lgAndUp ? 'calc(25% - 2rem)' : (breakpoint.mdAndUp ? 'calc(50% - 2rem)' : '100%')
              }}>
              <div
                className={'relative  mb-6'}>
                <div
                  className={'bg-white rounded-full mx-auto p-3'}
                  style={{
                    height: '4.5rem',
                    width: '4.5rem'
                  }}>
                  <Image
                    src={benefit.icon.meta.downloadUrl}
                    height={48}
                    width={48}
                  />
                </div>
              </div>
              <div
                className={'relative'}>
                <Typography
                  weight={'black'}
                  size={'five'}
                  className={'text-white text-center mb-1'}>
                  {benefit.title}
                </Typography>
                <Typography
                  size={'two'}
                  className={'text-white text-center'}>
                  {benefit.text}
                </Typography>
              </div>
            </div>
          })
        }
      </div>
    </div>
  </section >
}

Header.propTypes = {
  title: PropTypes.string,
  highlightedTitleText: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.object,
  bannuBannerText: PropTypes.string,
  benefits: PropTypes.array,
  integrationSettings: PropTypes.object
}
export default Header
