import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useRouter } from 'next/router'

import Spinner from '../../spinner'

import { useAuth } from '../../../../hooks/auth'
import { formatURI } from '../../../../api/common/utils'
import { getUser, createSpecializationEnrollment } from '../../../../api/bantrab'

import ArrowRight from '../../icon/arrow-right.svg'

const Action = ({ specialization, integrationSettings, ...attrs }) => {
  const auth = useAuth()
  const router = useRouter()

  const [isLoading, setIsLoading] = useState(false)
  // const [isRegistered, setIsRegistered] = useState(false)

  const handleClick = async (event) => {
    event.preventDefault()
    event.stopPropagation()

    if (!auth.isAuthenticated) {
      router.push({
        pathname: '/auth/signin',
        query: {
          nexturl: router.asPath
        }
      })
    } else {
      setIsLoading(true)
      const enrollment = await auth.createSpecializationEnrollment(
        {
          specialization: specialization.id
        },
        createSpecializationEnrollment
      )
      auth.getUser({}, getUser)

      const baseURL = 'https://www.crehana.com'
      const basePath = `/api/rest/org/${integrationSettings.organizationSlug}/sso-auth/`

      const query = {
        api_key: integrationSettings.apiKey,
        token: enrollment.token,
        next_url: enrollment.url || 'https://www.crehana.com/'
      }

      const url = formatURI(baseURL, basePath, query)

      if (typeof window !== 'undefined') {
        const newWindow = window.open(url, '_blank')
        newWindow && newWindow.focus()
      }

      setIsLoading(false)
    }
  }

  /*
  /* TODO: check if user is already subcribed to an specialization
  useEffect(() => {
    if (auth && auth.isAuthenticated) {
      const { user } = auth
      if (user) {
        const { achievements = [] } = user
        setIsRegistered(achievements.find(item => item.course.course.id === course.crehanaCourseId) !== undefined)
      }
    }
  }, [auth])
  */

  attrs.className = classNames(
    attrs.className,
    [
      'relative',
      'flex',
      'flex-row',
      'items-center',
      'justify-center',
      'py-3',
      'px-3',
      isLoading ? 'cursor-not-allowed' : 'cursor-pointer'
    ]
  )

  attrs.style = {
    ...(attrs.style || {}),
    outline: 'none',
    border: 'hidden',
    borderRadius: '0 1.5rem 1.5rem 1.5rem',
    minHeight: '3rem'
  }

  attrs.onClick = isLoading ? (event) => { event.preventDefault(); event.stopPropagation() } : handleClick

  return <div
    {...attrs}>
    {
      isLoading
        ? <Spinner
          color={'white'}
          className={'absolute -top-4 -bottom-4 left-0 rigth-0 w-full cursor-not-allowed'} />
        : <>
          Comienza ahora
          <ArrowRight
            width={24}
            height={24}
            className={'ml-2'} />
        </>
    }
  </div>
}

Action.propTypes = {
  specialization: PropTypes.object,
  integrationSettings: PropTypes.object
}

export default React.memo(Action)
