import PropTypes from 'prop-types'
import classNames from 'classnames'

import Image from '../image'
import Typography from '../typography'
import SubscriptionAction from '../specializations/action/subscribe'

import { useBreakpoint } from '../../../hooks/breakpoint'

import Bullet from '../../../public/img/bantrab/bullet-01.svg'
import GuateAprendeIcon from '../../../public/img/bantrab/logo-bannu-03.svg'
import EmprendoPorTi from '../../../public/img/bantrab/emprendo_por_ti.svg'

const Specializations = ({ title, highlightedTitleText1, highlightedTitleText2, specializations, integrationSettings, ...attrs }) => {
  const breakpoint = useBreakpoint()

  title = title.replace(new RegExp(`\\b${highlightedTitleText1.trim()}\\b`, 'g'), '')
  title = title.replace(new RegExp(`\\b${highlightedTitleText2.trim()}\\b`, 'g'), '')

  attrs.className = classNames(
    attrs.className,
    [
      'relative',
      'bg-turquoise-50',
      'py-20',
      'md:py-24',
      'md:bg-white'
    ]
  )

  return <section
    {...attrs}>

    <div
      className="relative container px-4 grid grid-cols-1 gap-8">
        {/*
      <div
        className="grid grid-cols-5 gap-16">
        <div
          className="relative z-0 text-center md:text-right col-span-5 md:col-span-3 lg:col-span-3">
          <Typography
            weight={'black'}
            size={breakpoint.mdAndUp ? 'seven' : 'five'}
            className="text-green-600 mb-10">
            {title}{' '}
            <Typography
              tag={'span'}
              weight={'black'}
              size={breakpoint.mdAndUp ? 'five' : 'four'}
              className={'text-green-500'}>
              {highlightedTitleText1}
            </Typography>
            {
              highlightedTitleText2.toLowerCase().trim() === 'emprendo por ti'
                ? <EmprendoPorTi
                  style={{ width: '100%' }}
                  className="block h-24" />
                : <Typography
                  tag={'span'}
                  weight={'black'}
                  size={breakpoint.mdAndUp ? 'eight' : 'six'}
                  className={'text-red-500 block'}>
                  {highlightedTitleText2}
                </Typography>
            }
          </Typography>
          <GuateAprendeIcon
            className="absolute z-0 -top-12 -right-16 transform translate-x-full"
            width={256}/>
        </div>

      </div>
      */}
      {
        specializations.map(
          (item, index) => {
            return <div
              key={index}
              className={'relative z-10 px-4 md:px-12 lg:px-16 py-4'}>
              <div className="relative">
                <div
                  className={
                    classNames([
                      'hidden',
                      'absolute',
                      'md:block',
                      'md:-top-8',
                      'md:-left-6',
                      'lg:-left-16',
                      'md:w-5/6',
                      'md:h-full',
                      'z-0',
                      (index % 2 === 0) ? 'bg-gray-200' : 'bg-green-500'
                    ])
                  }
                  style={{
                    borderRadius: '2.5rem 2.5rem 0 2.5rem'
                  }}>
                </div>

                <div
                  className={'z-10 relative bg-white md:left-12 grid grid-cols-2 shadow'}
                  style={{
                    borderRadius: '2.5rem 2.5rem 2.5rem 2.5rem'
                  }}>
                  <div
                    className={
                      classNames([
                        'p-3',
                        'col-span-2',
                        'md:col-span-1',
                        'md:py-4',
                        'md:px-10',
                        (index % 2 === 0) ? 'bg-red-500' : 'bg-green-600'
                      ])
                    }
                    style={{
                      borderRadius: `2.5rem ${breakpoint.mdAndUp ? '0' : '2.5rem'} 2.5rem 0`
                    }}>
                    <Typography
                      weight={'bold'}
                      size={breakpoint.mdAndUp ? 'five' : 'three'}
                      className={'text-white text-center md:text-left'}>
                      {item.specialization.title}
                    </Typography>
                  </div>

                  <div
                    className={
                      classNames([
                        'grid',
                        'grid-cols-1',
                        'md:grid-cols-2',
                        'md:grid-flow-col-dense',
                        'col-span-2'
                      ])
                    }>
                    <div
                      className={'p-6 relative md:col-start-2'}>
                      <div
                        className={'relative md:-mt-12'}>
                        <Image
                          src={item.image.meta.downloadUrl}
                          className={'relative w-full'}
                          style={{
                            borderRadius: '2.5rem'
                          }} />
                      </div>
                    </div>

                    <div
                      className={'p-6 md:col-start-1'}>
                      <Typography
                        size={breakpoint.mdAndUp ? 'three' : 'two'}
                        className={'mb-6'}>
                        {item.description}
                      </Typography>

                      <Typography
                        weight={'bold'}
                        size={breakpoint.mdAndUp ? 'four' : 'three'}
                        className={
                          classNames([
                            'mb-6',
                            ((index % 2) === 0) ? 'text-red-600' : 'text-green-600'
                          ])
                        }>
                            ¿Qué aprenderás en este programa?
                      </Typography>

                      <div>
                        {
                          item.learnings.map(
                            (learning, lIndex) => {
                              return <div
                                key={lIndex}
                                className={
                                  classNames([
                                    'pl-4',
                                    'relative',
                                    ((lIndex > 0) ? 'mt-2' : '')
                                  ])
                                }>
                                <Bullet
                                  className={
                                    classNames([
                                      'absolute',
                                      'left-0',
                                      'top-1/4',
                                      'transform',
                                      '-translate-y-1/2',
                                      'h-2',
                                      ((index % 2) === 0) ? 'text-red-500' : 'text-green-500'
                                    ])
                                  } />
                                {learning}
                              </div>
                            }
                          )
                        }
                      </div>

                      <SubscriptionAction
                        specialization={item.specialization}
                        integrationSettings={integrationSettings}
                        className={
                          classNames(
                            [
                              'text-white',
                              'w-full',
                              'mt-4'
                            ],
                            (index % 2 === 0)
                              ? [
                                'bg-red-500',
                                'hover:bg-red-400',
                                'active:bg-red-600'
                              ]
                              : [
                                'bg-green-500',
                                'hover:bg-green-400',
                                'active:bg-green-600'
                              ]
                          )
                        } />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        )
      }
    </div>
  </section >
}

Specializations.propTypes = {
  title: PropTypes.string,
  highlightedTitleText1: PropTypes.string,
  highlightedTitleText2: PropTypes.string,
  specializations: PropTypes.array,
  integrationSettings: PropTypes.object
}

export default Specializations
