import { useState } from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Link from 'next/link'

import Typography from '../typography'
import Card from '../courses/card'
import Image from '../image'

import {
  PROVIDERS,
  PROVIDERS_ICON_MAP,
  PROVIDERS_TEXT_MAP,
  PROVIDERS_COLOR_MAP,
  PROVIDERS_BWICON_MAP
} from '../courses/constants'
import { useBreakpoint } from '../../../hooks/breakpoint'

const Courses = ({ title, highlightedTitleText, courses, integrationSettings, ...attrs }) => {
  title = title.replace(highlightedTitleText, '')

  const providers = Object.keys(courses).map(
    provider => {
      const count = get(courses[provider], 'meta.totalCount', 0)
      if (count === 0 || !PROVIDERS.includes(provider)) {
        return null
      }
      return provider
    }
  ).filter(
    provider => provider !== null
  )

  const breakpoint = useBreakpoint()
  const [activeProvider, setActiveProvider] = useState(providers[0])

  attrs.className = classNames(
    attrs.className,
    [
      'relative',
      'py-20',
      'md:py-24',
      'md:bg-white'
    ]
  )

  return <section {...attrs}>
    <div
      className={
        classNames([
          'relative',
          'container',
          'px-4',
          'mb-10'
        ])
      }>
      <Typography
        weight={'black'}
        size={breakpoint.mdAndUp ? 'eight' : 'six'}
        className={'text-turquoise-600 text-center mb-4'}>
        {title}{' '}
        <Typography
          tag={'span'}
          weight={'black'}
          size={breakpoint.mdAndUp ? 'eight' : 'six'}
          className={'text-yellow-500'}>
          {highlightedTitleText}
        </Typography>
      </Typography>
    </div>

    <div
      className={
        classNames([
          'relative',
          'container',
          'px-4',
          'mb-10'
        ])
      }>
      <div
        className={
          classNames([
            'flex',
            'flex-row',
            'overflow-auto'
          ])
        }>
        {
          providers.map(
            (provider, index) => {
              const isActive = (activeProvider === provider)
              let Logo = isActive ? PROVIDERS_ICON_MAP[provider] : PROVIDERS_BWICON_MAP[provider]
              // Forzamos el logo de Banconsejos cuando sean cursos de Bancaprende
              if(provider == 'bancaaprende'){
                Logo = isActive ? PROVIDERS_ICON_MAP['banconsejos'] : PROVIDERS_BWICON_MAP['banconsejos']
              }
              return <div
                key={index}
                className={
                  'flex-none sm:flex-1 cursor-pointer'
                }
                style={{
                  flexBasis: breakpoint.mdAndUp ? '33.333333%' : null
                }}
                onClick={() => setActiveProvider(provider)}>
                <div
                  className={'px-4'}>
                  <Typography
                    size={breakpoint.mdAndUp ? 'two' : 'one'}
                    weight={'bold'}
                    className={
                      classNames([
                        'mb-2',
                        'text-center',
                        isActive ? `text-${PROVIDERS_COLOR_MAP[provider]}` : 'text-gray-400'
                      ])
                    }
                    style={{
                      whiteSpace: 'nowrap'
                    }}>
                    {PROVIDERS_TEXT_MAP[provider]}
                  </Typography>
                  {
                    provider === 'lachiripa'
                      ? <Image
                        src={Logo}
                        width={176}
                        height={32}
                        className={
                          classNames(
                            [
                              'block',
                              'mx-auto'
                            ],
                            {
                              'text-gray-400': !isActive
                            }
                          )
                        } />
                      : <Logo
                        height={32}
                        className={
                          classNames(
                            [
                              'block',
                              'mx-auto'
                            ],
                            {
                              'text-gray-400': !isActive
                            }
                          )
                        } />
                  }
                </div>
                <div
                  className={
                    classNames([
                      'mt-4',
                      'border-t',
                      isActive ? 'border-turquoise-500' : 'border-transparent'
                    ])
                  }>
                  <div
                    className={
                      classNames([
                        'border-b',
                        isActive ? 'border-turquoise-500' : 'border-gray-400'
                      ])
                    } />
                </div>
              </div>
            }
          )
        }
      </div>
    </div>

    <div
      className={
        classNames([
          'relative',
          'container',
          'px-4',
          'mb-10'
        ])
      }>
      <div
        className={
          classNames([
            'flex',
            'flex-row',
            'flex-wrap',
            'justify-center',
            '-m-4'
          ])
        }>
        {
          providers.map(provider => {
            const isActive = (activeProvider === provider)
            const items = courses[provider].items
            return isActive
              ? items.map(
                item => {
                  return <Card
                    key={item.id}
                    course={item}
                    integrationSettings={integrationSettings} />
                }
              )
              : null
          })
        }
      </div>
    </div>

    <div
      className={
        classNames([
          'relative',
          'container',
          'px-4'
        ])
      }>
      <Link
        href={activeProvider == 'bancaaprende' ? '/cursos-bancaprende' : '/cursos'}>
        <a
          className={
            classNames([
              'w-full',
              'flex',
              'flex-row',
              'justify-center',
              'text-center',
              'border',
              'border-red-500',
              'text-red-500',
              'py-3',
              'mx-auto',
              'hover:border-red-400',
              'hover:text-red-400',
              'active:border-red-600',
              'active:text-red-600'
            ])
          }
          style={{
            maxWidth: breakpoint.mdAndUp ? '240px' : '320px',
            borderRadius: '0 1.5rem 1.5rem 1.5rem'
          }}>
          Ver más cursos
        </a>
      </Link>
    </div>
  </section>
}

Courses.propTypes = {
  title: PropTypes.string,
  highlightedTitleText: PropTypes.string,
  courses: PropTypes.object,
  integrationSettings: PropTypes.object
}

export default Courses
