import PropTypes from 'prop-types'
import classNames from 'classnames'
import Image from 'next/image'

import Typography from '../typography'

import { useBreakpoint } from '../../../hooks/breakpoint'

import styles from '../../../styles/bantrab/components/home.module.scss'

const Applications = ({ title, highlightedTitleText, googlePlayStore, appleAppStore, image, ...attrs }) => {
  const breakpoint = useBreakpoint()

  title = title.replace(highlightedTitleText, '')

  attrs.className = classNames(
    attrs.className,
    [
      'relative',
      'bg-turquoise-50',
      'py-20',
      'md:py-24',
      'md:bg-white'
    ],
    {
      [styles.aplications]: true
    }
  )

  return <section {...attrs}>
    <div
      className={
        classNames([
          'relative',
          'container',
          'p-4'
        ])
      }>
      <div
        className={
          classNames([
            'flex',
            'flex-row',
            'flex-wrap',
            'justify-center',
            'items-center',
            '-m-4'
          ])
        }>
        <div
          className={'m-4'}
          style={{
            borderRadius: '0 1.5rem 1.5rem 1.5rem',
            flexBasis: breakpoint.lgAndUp ? 'calc(40% - 2rem)' : (breakpoint.mdAndUp ? 'calc(50% - 2rem)' : '100%')
          }}>
          <Typography
            weight={'black'}
            size={breakpoint.mdAndUp ? 'eight' : 'six'}
            className={'text-green-600 mb-10 text-center md:text-left'}>
            {title}
            <Typography
              tag={'span'}
              weight={'black'}
              size={breakpoint.mdAndUp ? 'eight' : 'six'}
              className={'text-turquoise-600'}>
              {highlightedTitleText}
            </Typography>
          </Typography>

        </div>
        <div
          className={'m-4 overflow-hidden'}
          style={{
            borderRadius: '1.5rem 1.5rem 1.5rem 1.5rem',
            flexBasis: breakpoint.lgAndUp ? 'calc(40% - 2rem)' : (breakpoint.mdAndUp ? 'calc(50% - 2rem)' : '100%')
          }}>
          <Image
            src={image.meta.downloadUrl}
            width={image.width}
            height={image.height}
          />
        </div>
      </div>
    </div>
  </section>
}

Applications.propTypes = {
  title: PropTypes.string,
  highlightedTitleText: PropTypes.string,
  googlePlayStore: PropTypes.string,
  appleAppStore: PropTypes.string,
  image: PropTypes.object
}
export default Applications
