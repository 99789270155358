import PropTypes from 'prop-types'

import View from '../components/bantrab/view'
import { getBaseViewInfo } from '../components/bantrab/view/functions'

import Header from '../components/bantrab/home/header'
import Courses from '../components/bantrab/home/courses'
import Specializations from '../components/bantrab/home/specializations'
import Teachers from '../components/bantrab/home/teachers'
import Features from '../components/bantrab/home/features'
import Applications from '../components/bantrab/home/applications'

import { getCourses } from '../api/bantrab/index'
import { COURSES_LIST_QUERY } from '../api/bantrab/queries'
import { PROVIDERS } from '../components/bantrab/courses/constants'

const COURSES_PER_PAGE = parseInt(process.env.NEXT_PUBLIC_BANTRAB_COURSES_PER_PAGE_ON_INDEX)

async function getServerSideProps () {
  const { head, navbar, footer, raw } = await getBaseViewInfo()
  const body = raw.rootPage.body
  let hasCourseSection = false
  let courseSectionIndex = -1

  body.forEach(
    (section, index) => {
      if (section.type === 'applications') {
        body[index].value = {
          ...body[index].value,
          ...raw.siteInfo.mobileapplicationssettings
        }
      }
      if (section.type === 'courses') {
        hasCourseSection = true
        courseSectionIndex = index
      }
    }
  )

  if (hasCourseSection) {
    let courses = await Promise.all(
      PROVIDERS.map(
        provider => getCourses({
          ...COURSES_LIST_QUERY,
          limit: COURSES_PER_PAGE,
          provider: provider,
          has_specialization: false
        })
      )
    )

    courses = await Promise.all(
      courses.map(
        item => item.json()
      )
    )

    courses = courses.map(
      (item, index) => [PROVIDERS[index], item]
    )

    body[courseSectionIndex].value = {
      ...body[courseSectionIndex].value,
      courses: Object.fromEntries(courses)
    }
  }

  return {
    props: {
      head,
      navbar,
      body,
      footer,
      integrationSettings: raw.siteInfo.crehanaintegrationsettings
    }
  }
}

const HomeView = ({ head, navbar, body, footer, integrationSettings }) => {
  return <View
    {...{ head, navbar, footer }}>
    {
      body.map((section, index) => {
        if (section.type === 'header') {
          return <Header
            key={section.id}
            id={section.id}
            order={index}
            integrationSettings={integrationSettings}
            {...section.value}
          />
        }
        if (section.type === 'courses') {
          return <Courses
            key={section.id}
            id={section.id}
            order={index}
            integrationSettings={integrationSettings}
            {...section.value}
          />
        }
        if (section.type === 'specializations') {
          return <Specializations
            key={section.id}
            id={section.id}
            order={index}
            integrationSettings={integrationSettings}
            {...section.value}
          />
        }
        if (section.type === 'teachers') {
          return <Teachers
            key={section.id}
            id={section.id}
            order={index}
            {...section.value}
          />
        }
        if (section.type === 'features') {
          return <></>
        }
        if (section.type === 'applications') {
          return <Applications
            key={section.id}
            id={section.id}
            order={index}
            {...section.value}
          />
        }

        return null
      })
    }
  </View>
}

HomeView.propTypes = {
  head: PropTypes.object,
  navbar: PropTypes.object,
  body: PropTypes.array,
  footer: PropTypes.object,
  integrationSettings: PropTypes.object
}

export { getServerSideProps }
export default HomeView
